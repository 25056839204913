<script>
import * as Msal from "@azure/msal-browser";

import {
    BaseConfig,
    CoreAPIConfig
} from "../../../state/api/config";

export default {
    page: {
        title: 'Login',
    },
    data() {
        return {
            currentAccount: null,
            loginInProgress: false
        }
    },
    async created() {
        this.cleanUpBeforeLogin();
        this.loginRequest = {
            redirectStartPage: `${BaseConfig.AppUrl}completeLogin`,
            scopes: BaseConfig.AppScope
        };
        this.setupClientApplication();
    },
    computed: {
        isProgressing() {
            return this.$store.state.isProgressing
        }
    },
    methods: {
        cleanUpBeforeLogin() {
            var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i];
                var eqPos = cookie.indexOf("=");
                var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
            for (var obj in sessionStorage) {
                sessionStorage.removeItem(obj);
            }
        },
        async login() {
            this.loginInProgress = true;
            this.$store.dispatch("setIsProgressing", true);
            this.app.loginRedirect(this.loginRequest);
        },
        setupClientApplication() {
            this.app = new Msal.PublicClientApplication({
                auth: {
                    clientId: `${BaseConfig.AppClientID}`,
                    authority: `${BaseConfig.AppLoginAuthority}`,
                    knownAuthorities: [`${BaseConfig.AppLoginAuthority}`],
                    validateAuthority: false,
                    navigateToLoginRequestUrl: true,
                    redirectUri: `${BaseConfig.AppUrl}completelogin`,
                    postLogoutRedirectUri: `${BaseConfig.AppUrl}login`,
                },
                cache: {
                    cacheLocation: "sessionStorage",
                    storeAuthStateInCookie: true,
                    secureCookies: true
                }
            });
        }
    }
}
</script>

<template>
    <div class="account-pages my-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-6">
                    <div class="card">
                        <div class="card-body p-0">
                            <div class="row">
                                <vs-progress indeterminate color="primary ml-3 mr-3"
                                    v-show="loginInProgress"></vs-progress>
                                <div class="col-lg-12 p-5 text-center">
                                    <div class="mx-auto mb-5">
                                        <a href="/">
                                            <img src="@assets/images/LogoLight.png" alt height="72" />
                                        </a>
                                        <p class="text-muted mt-0 mb-4">Administration</p>
                                    </div>

                                    <div class="authentication-form mt-4">
                                        <p class="mt-0 mb-4" v-if="loginInProgress === true">Please wait while we log
                                            you in.</p>
                                        <div class="form-group mb-0 text-center" v-if="loginInProgress === false">
                                            <b-button class="btn btn-lg btn-primary btn-block mt-10" @click="login"
                                                variant="primary">Login</b-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12 text-center">
                            <p class="text-muted">
                                Copyright © 2024 tawcs | All rights reserved
                                <br>
                                <a target="_blank" href="https://docs.evacone.com.au/#/mobile/terms"
                                    class="text-primary font-weight-bold mr-1">Terms of Use</a> |
                                <a target="_blank" href="https://docs.evacone.com.au/#/mobile/privacy"
                                    class="text-primary font-weight-bold ml-1">Privacy Policy</a>
                            </p>
                        </div>
                        <!-- end col -->
                    </div>
                    <!-- end row -->
                </div>
                <!-- end col -->
            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </div>
</template>

<style lang="scss" module></style>
